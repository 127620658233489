import React from 'react';

function Layout({ children }) {
  return (
    <div className="App">
      <h1>
        <span className="Google-logo">G</span>
        <span className="Google-logo-red">o</span>
        <span className="Google-logo-yellow">o</span>
        <span className="Google-logo">g</span>
        <span className="Google-logo-green">l</span>
        <span className="Google-logo-red">e</span> Calendar Integration
      </h1>
      
    <h2>
        Identifying shared free time, so you can focus on meeting, not scheduling.
      </h2>

      <div className="content-wrapper">
        {children}
      </div>
    </div>
  );
}

export default Layout;

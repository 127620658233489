import React, { useState, useEffect, useRef } from 'react';

function InfoNote() {
  const [showNote, setShowNote] = useState(false);
  const noteRef = useRef(null);
  const iconRef = useRef(null);

  // HTML structure for instructions
  const note = `
    <p><strong>Before starting to test the application:</strong></p>
    <ul>
      <li>Ensure third-party cookies are enabled in your browser settings, as server-side cookies are set for storing related information.</li>
      <li>If you're using Brave, please turn off Brave Shields to allow the application to function correctly.</li>
      <li>Navigate to the <strong>Network</strong> tab to monitor API requests. Ensure all API calls are fulfilled (you may see a slight delay due to server-side data insertion before the response is sent).</li>
      <li>The Creator of the QR code can ask his friends to scan it for authorization and the Creator should use the "use above QR code button" to authorize and fetch his events.</li>
      <li>Right now the creator of the QR code should use the "use QR button" after everyone scans the QR.</li>   
    </ul>  `;

  // Toggle display of the note
  const toggleNote = () => setShowNote((prev) => !prev);

  // Close the tooltip if clicked outside the tooltip or icon
  const handleClickOutside = (event) => {
    if (
      noteRef.current &&
      !noteRef.current.contains(event.target) &&
      iconRef.current &&
      !iconRef.current.contains(event.target)
    ) {
      setShowNote(false);
    }
  };

  useEffect(() => {
    if (showNote) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    // Cleanup event listener on component unmount or when `showNote` changes
    return () => document.removeEventListener('click', handleClickOutside);
  }, [showNote]);

  return (
    <div className="info-note">
      <div className="info-icon" onClick={toggleNote} ref={iconRef}>ℹ️</div>
      {showNote && (
        <div className="note-tooltip" ref={noteRef} dangerouslySetInnerHTML={{ __html: note }} />
      )}
    </div>
  );
}

export default InfoNote;
